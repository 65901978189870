import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { RollbackPopupStyle } from "./style";
import closeIcon from "@assets/images/close.svg";

function RollbackPopup(props) {
  const classes = RollbackPopupStyle();
  const [scroll] = useState("body");
  return (
    <div className={classes.RollbackWrapper}>
      <Dialog
        open={props.open}
        scroll={scroll}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.customModal}
      >
        <div className="close-modal">
          <img src={closeIcon} alt="Close" onClick={props.handleClose}/>
        </div>
        {props.error && <Alert severity="error">{props.error}</Alert>}
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to rollback the merge?<br/>This will undo all change made since the merge.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.handleRollback}
            color="primary"
            className="orange-btn primary-btn"
          >
            {props.loading ? (
              <CircularProgress color="inherit"/>
            ) : (
              "ROLLBACK"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RollbackPopup;
