import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import {
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useFormik } from "formik";
import moment from "moment";

import { useStore } from "@store/store";
import { useDebouncedEffect } from "@hooks/debounceEffect";
import {
  ADD_AWB,
  ADD_AWB_FAILURE,
  ADD_AWB_SUCCESS,
  DELETE_AWB,
  DELETE_AWB_FAILURE,
  DELETE_AWB_SUCCESS,
  EDIT_AWB,
  EDIT_AWB_FAILURE,
  EDIT_AWB_SUCCESS,
  VALIDATE_AWB,
  VALIDATE_AWB_SUCCESS,
  VALIDATE_AWB_FAILURE,
  FETCH_AIR_WAY_BILLS,
  FETCH_AIR_WAY_BILLS_FAILURE,
  FETCH_AIR_WAY_BILLS_SUCCESS,
} from "@utils/actionTypes";
import DeletePopup from "@components/deletePopup";
import SearchIcon from "@assets/images/search.svg";
import { schema } from "@utils/schemas";
import { jobStatus, routes, actionPermission, sourceId } from "@utils/constant";
import validationSchema from "@utils/validationSchemas";
import API from "@services/axios";
import AddAirWayBill from "./add-bill";
// import AddCustomerAWB from "./add-customer-awb";
import { AirWayBillStyle } from "./style";
import ULD from "./uld";
import Loose from "./loose";
import MergeAwbPopup from "./merge-awb-popup";
import InfoIcon from "@mui/icons-material/Info";
import {
  FETCH_THE_SAME_AIR_WAY_BILLS,
  FETCH_THE_SAME_AIR_WAY_BILLS_FAILURE,
  FETCH_THE_SAME_AIR_WAY_BILLS_SUCCESS
} from "@utils/actionTypes";
import {
  getPermissions,
  isRolePermission,
  actionFromPermission
} from "@utils/commonFunctions";
import {
  MERGE_JOB_FAILURE,
  MERGE_JOB_SUCCESS
} from "@utils/actionTypes";

function AirWayBill(props) {
  const classes = AirWayBillStyle();
  const [openAddAwb, setOpenAddAwb] = useState(false);
  // const [openCustomerAwb, setOpenCustomerAwb] = useState(false);
  const [search, setSearch] = useState("");
  const [initialValue, setInitialValue] = useState(schema.addAirWayBillSchema);
  const [getIndex, setIndex] = useState("");
  const [isEditAwb, setEditAwb] = useState(false);
  const [openDeletePopup, setDeletePopup] = useState(false);
  const [openMergeAwbPopup, setOpenMergeAwbPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [error, setError] = useState("");
  const [awbSame, setAwbSame] = useState([]);
  const [state, dispatch] = useStore();
  const { id } = useParams();
  const history = useHistory();
  const [awbFormikValue, setAwbFormikValue] = useState(null);
  const [jobExists, setJobExists] = useState(null);

  const data = (value) => {
    let obj = {
      id: value.id,
      number: value.number,
    };
    obj = {
      ...obj,
      ...(!!value.weight ? { weight: value.weight } : {}),
      ...(!!value.flight ? { flight: value.flight } : {}),
      ...(!!value.quantity ? { quantity: value.quantity } : {}),
      ...(!!value.readyDate
        ? { readyDate: moment(value.readyDate).format("YYYY-MM-DD") }
        : {}),
      ...(!!value.readyTime
        ? { readyTime: moment(value.readyTime).format("HH:mm:ss") }
        : {}),
      ...(!!value.cutOffTime
        ? { cutOffTime: moment(value.cutOffTime).format("HH:mm:ss") }
        : {}),
    };
    return obj;
  };

  let getSameAWB = () => {
    dispatch({ type: FETCH_THE_SAME_AIR_WAY_BILLS });
      API.get(`jobs/${id}/airWaybills/getSameAWB`)
        .then((response) => {
          setAwbSame(response.data.data);
          dispatch({
            type: FETCH_THE_SAME_AIR_WAY_BILLS_SUCCESS,
            payload: response.data.data,
          });
          getAirWayBills();
        })
        .catch((error) => {
          dispatch({ type: FETCH_THE_SAME_AIR_WAY_BILLS_FAILURE, payload: error });
          if (error?.response?.status === 404) {
            history.push(routes.pageNotFound);
          }
        });
  };

  // API calling to get airway bills
  let getAirWayBills = () => {
    const params = {
      ...(!!search ? { search } : {}),
    };
    if (
      ["Import", "Export", "Temp Control"].includes(
        props.jobsData?.jobTypes?.name,
      )
    ) {
      dispatch({ type: FETCH_AIR_WAY_BILLS });
      API.get(`jobs/${id}/airWaybills`, { params })
        .then((response) => {
          dispatch({
            type: FETCH_AIR_WAY_BILLS_SUCCESS,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          dispatch({ type: FETCH_AIR_WAY_BILLS_FAILURE, payload: error });
          if (error?.response?.status === 404) {
            history.push(routes.pageNotFound);
          }
        });
    }
  };

  // API calling to add AWB
  let addAWB = (value) => {
    dispatch({ type: ADD_AWB });
    API.post(`jobs/${id}/airWaybills`, data(value))
      .then((response) => {
        dispatch({
          type: ADD_AWB_SUCCESS,
          payload: response.data.data,
        });
        handleClose();
        getAirWayBills();
        toast.success("AirWayBill Added Successfully");
        props.getJobs();
      })
      .catch((err) => {
        dispatch({ type: ADD_AWB_FAILURE, payload: err });
        setError(err.response.data.message);
      });
  };

  let mergeAWB = async () => {
    const { values } = awbFormik;
    const payload = {
      ...props.jobsData,
      customerId: parseInt(props.jobsData?.customers?.id, 10),
      jobTypeId: parseInt(props.jobsData?.jobTypes?.id, 10),
      cargoTypeId: parseInt(props.jobsData?.cargoTypes?.id, 10),
      ctoId: parseInt(props.jobsData?.ctos?.id, 10),
      cityId: parseInt(props.jobsData?.cities?.id, 10),
      jobStatusId: parseInt(props.jobsData?.jobStatuses?.id, 10),
      isPreloadChargeApplied: jobExists?.isPreloadChargeApplied || props.jobsData?.isPreloadChargeApplied,
      awbId: values.id,
      number: values.number,
      quantity: values.quantity,
      weight: values.weight,
      readyTime:values.readyTime ? moment(values.readyTime).format("HH:mm:ss") : null,
      readyDate: values.readyDate ? moment(values.readyDate).format("YYYY-MM-DD") : null,
      cutOffTime: values.cutOffTime ? moment(values.cutOffTime).format("HH:mm:ss") : null
    };

    // Merge Jobs
    await API.put(
      `jobs/${jobExists?.id}/mergeJob`,
      payload,
    ).then((response) => {
        dispatch({
          type: MERGE_JOB_SUCCESS,
          payload: response?.data?.data,
        });
        handleClose();
        toast.success("Job Merged Successfully");
        const targetStatusId = jobExists?.jobStatusId;
        const sourceStatusId = props.jobsData?.jobStatuses?.id;
        if ((sourceStatusId === 3 || sourceStatusId === 1) && targetStatusId !== 1 && targetStatusId !== 3) {
          history.push(`${routes.jobDetail}/${jobExists?.id}`);
        } else {
          history.push(`${routes.jobDetail}/${props.jobsData?.id}`);
        }
        props.getJobs();
    })
      .catch((err) => {
        dispatch({ type: MERGE_JOB_FAILURE, payload: err });
        setError(err.response.data.message);
      });
  };

  // API calling to edit AWB
  let editAWB = (value) => {
    dispatch({ type: EDIT_AWB });
    API.put(`jobs/${id}/airWaybills/${value.id}`, data(value))
      .then((response) => {
        dispatch({
          type: EDIT_AWB_SUCCESS,
          payload: response.data.data,
        });
        handleClose();
        getAirWayBills();
        toast.success("AirWayBill Updated Successfully");
        props.getJobs();
      })
      .catch((err) => {
        dispatch({ type: EDIT_AWB_FAILURE, payload: err });
        setError(err.response.data.message);
      });
  };

  // API calling to delete AWB
  let deleteAWB = (value) => {
    dispatch({ type: DELETE_AWB });
    API.delete(`jobs/${id}/airWaybills/${value}`)
      .then((response) => {
        setDeletePopup(false);
        getAirWayBills();
        dispatch({
          type: DELETE_AWB_SUCCESS,
          payload: response.data.data,
        });
        toast.success("AirwayBill Deleted Successfully");
        props.getJobs();
      })
      .catch((error) => {
        if (error.response.data.code === 409) {
          setError(error.response?.data?.message);
          toast.error(error.response?.data?.message);
        }
        dispatch({ type: DELETE_AWB_FAILURE, payload: error });
      });
  };

  useEffect(() => {
    getSameAWB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDebouncedEffect(() => getAirWayBills(), 1000, [search]);
  const handleSearch = (event) => setSearch(event.target.value);
  const handleCloseSidebar = () => setAnchorEl(null);

  const handleClick = (event, index) => {
    setIndex(index);
    setAnchorEl(event.currentTarget);
  };

  // To handle and prefill initial value while editing AWB
  const handleEditOpen = (item) => {
    setInitialValue({
      number: item.number,
      flight: item.flight,
      weight: item.weight ? item.weight : "",
      quantity: item.quantity ? item.quantity : "",
      readyDate: item.readyDate ? item.readyDate : null,
      readyTime: item.readyTime ? moment(item.readyTime, "hh:mm:ss") : null,
      cutOffTime: item.cutOffTime ? moment(item.cutOffTime, "hh:mm:ss") : null,
      weightLoaded: item.weightLoaded ? item.weightLoaded : 0,
      quantityLoaded: item.quantityLoaded ? item.quantityLoaded : 0,
      remainderQty: item.remainderQty ? item.remainderQty : 0,
      remainderReason: item.remainderReason ? item.remainderReason : "",
      id: item.id ? item.id : "",
    });
    setOpenAddAwb(true);
    setEditAwb(true);
    handleCloseSidebar();
  };

  const handleClickOpen = () => setOpenAddAwb(true);
  // const handleClickCustomerAwbOpen = () => setOpenCustomerAwb(true);

  const handleClose = () => {
    setOpenAddAwb(false);
    setEditAwb(false);
    setError("");
    setInitialValue(schema.addAirWayBillSchema);
    awbFormik.handleReset();
  };

  // const handleCloseCustomerAwb = () => {
  //   setOpenCustomerAwb(false);
  // };

  const [targetJobId, setTargetJobId] = useState(null);

  const handleCloseMergeAwbPopup = () => {
    dispatch({ type: VALIDATE_AWB_SUCCESS });
    setOpenMergeAwbPopup(false);
  };

  const handleAddEdit = () => {
    handleCloseMergeAwbPopup();
    if (isEditAwb) {
      editAWB(awbFormikValue);
    } else {
      addAWB(awbFormikValue);
    }
  };

  const handleMerge = () => {
    handleCloseMergeAwbPopup();
    mergeAWB(awbFormikValue);
  };

  const handleDeleteOpenPopup = (item) => {
    setInitialValue({
      ...initialValue,
      id: item.id,
      number: item.number,
    });
    setDeletePopup(true);
    setAnchorEl(null);
  };

  const closeDeletePopup = () => {
    setError("");
    setDeletePopup(false);
  };

  // check mismatch between quantity - quantityLoaded
  const isMismatch = (item) => {
    const sameAWB = awbSame.find(awb => awb.number === item.number);
    return (sameAWB && parseInt(sameAWB.quantityLoaded) === parseInt(item.quantity)) || parseInt(item.quantity) === parseInt(item.quantityLoaded);
  };

  const awbFormik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: validationSchema.addAirWayBillValidationSchema,
    onSubmit: async (value) => {
      try {
        dispatch({ type: VALIDATE_AWB });
        const response = await API.get(
          `/jobs/${props.jobsData.id}/airWaybills/awbNumber/${value.number}?id=${value.id}`
        );
        dispatch({ type: VALIDATE_AWB_SUCCESS });
        const { jobs, isValidMergeJob } = response.data.data;

        if (isValidMergeJob && jobs) {
          setTargetJobId(jobs?.id);
          setAwbFormikValue(value);
          setJobExists(jobs);
          setOpenMergeAwbPopup(true);
        } else {
          if (isEditAwb) {
            editAWB(value);
          } else {
            addAWB(value);
          }
        }
      } catch (err) {
        dispatch({ type: VALIDATE_AWB_FAILURE });
        setError(err.response.data.message);
      }
    },
  });

  return (
    <div className={classes.AirWayBillWrapper}>
      <div className={classes.tabHeadingRow}>
        <div className={classes.searchWrapper}>
          <div className="form-gourp">
            <TextField
              id="search-request"
              placeholder="Search air waybills"
              variant="outlined"
              type="search"
              value={search}
              onChange={handleSearch}
              InputProps={{
                endAdornment: <img src={SearchIcon} alt="Search" />,
              }}
            />
          </div>
        </div>
        <div className={classes.modalWrapper1}>
          <AddAirWayBill
            formik={awbFormik}
            handleClickOpen={handleClickOpen}
            open={openAddAwb}
            handleClose={handleClose}
            isEdit={isEditAwb}
            error={error}
            jobsData={props.jobsData}
          />
        </div>
      </div>
      {props.jobsData?.cargoTypes?.name === "ULD" ? (
        <div className={classes.customCardWrapper}>
          {state.job.airWayBillData?.count === 0 ? (
            <div className="custom-card">
              <div className="card-header">
                <span className="heading-title">No Data found</span>
              </div>
            </div>
          ) : (
            state.job.airWayBillData?.rows?.map((item, index) => {
              // Check if the current AWB number exists in the awbSame array
              const sameAWB = awbSame.find(awb => awb.number === item.number);
              return (
                <div className="custom-card" key={index}>
                  <div className="card-header">
                    <span className="heading-title">
                      {item.number}
                      {item.sourceId === 4 ? (
                        <span  className={classes.highlightCargoWise}>CargoWise</span>
                      ) : null}
                      {isMismatch(item) ? (
                        <Tooltip title="The total quantity listed on the AWB has been fully delivered" arrow placement="right">
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </span>
                    <div className="bill-items-wrapper">
                      <ULD
                        getIndex={item.id}
                        jobsData={props.jobsData}
                        getAirWayBills={getAirWayBills}
                        getJobs={() => props.getJobs()}
                        isMismatch={isMismatch(item)}
                      />
                      <div className={classes.moreLinkWrapper}>
                        <div className="more-link-block">
                          {item.sourceId === sourceId.CARGO_WISE && getPermissions() &&
                            isRolePermission(getPermissions(), "cargoWise") &&
                            (actionFromPermission(getPermissions(), "cargoWise") === actionPermission.READ_ONLY) && (
                          <span
                            className="more-link transparent"
                            aria-controls="menu"
                            aria-haspopup="true"
                            onClick={(e) => handleClick(e, index)}
                          >
                            <MoreVertIcon />
                          </span>)}

                          {item.sourceId === sourceId.CARGO_WISE && getPermissions() &&
                            isRolePermission(getPermissions(), "cargoWise") &&
                            (actionFromPermission(getPermissions(), "cargoWise") !== actionPermission.READ_ONLY) && (
                          <span
                            className="more-link"
                            aria-controls="menu"
                            aria-haspopup="true"
                            onClick={(e) => handleClick(e, index)}
                          >
                            <MoreVertIcon />
                          </span>)}

                          {item.sourceId !== sourceId.CARGO_WISE && (
                            <span
                              className="more-link"
                              aria-controls="menu"
                              aria-haspopup="true"
                              onClick={(e) => handleClick(e, index)}
                            >
                              <MoreVertIcon />
                            </span>)}
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={getIndex === index && Boolean(anchorEl)}
                            onClose={handleCloseSidebar}
                            className={classes.customMenu}
                            menuprops={{
                              classes: { paper: classes.customMenu },
                            }}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            {item.sourceId === sourceId.CARGO_WISE ? getPermissions() &&
                              isRolePermission(getPermissions(), "cargoWise") &&
                              ((actionFromPermission(getPermissions(), "cargoWise") === actionPermission.EDIT_CARGOWISE) ||
                                actionFromPermission(getPermissions(), "cargoWise") === actionPermission.ALL) && (
                              <MenuItem onClick={() => handleEditOpen(item)}>
                              {props.jobsData?.invoiceGenerated === false
                                ? "Edit"
                                : "View"}
                              </MenuItem>):
                              (<MenuItem onClick={() => handleEditOpen(item)}>
                                {props.jobsData?.invoiceGenerated === false
                                  ? "Edit"
                                  : "View"}
                              </MenuItem>)}

                            {item.sourceId === sourceId.CARGO_WISE ? getPermissions() &&
                              isRolePermission(getPermissions(), "cargoWise") &&
                              (actionFromPermission(getPermissions(), "cargoWise") === actionPermission.ALL) &&
                              props.jobsData?.invoiceGenerated === false && (
                                <MenuItem
                                  onClick={() => handleDeleteOpenPopup(item)}
                                >
                                  Delete
                                </MenuItem>
                              ):
                              props.jobsData?.invoiceGenerated === false && (
                                <MenuItem
                                    onClick={() => handleDeleteOpenPopup(item)}
                                >
                                  Delete
                                </MenuItem>
                              )}
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <ul>
                      <li>
                        <Typography variant="h6">Remainder Reason:</Typography>
                        <div className="value-block">
                          <p>
                            {item?.remainderQty > 0 && item?.remainderReason
                              ? item?.remainderReason
                              : "-"}
                          </p>
                        </div>
                      </li>
                      <li>
                        <Typography variant="h6">Weight:</Typography>
                        <div className="value-block">
                          <p>{item.weight ? `${item.weight} Kg` : "-"} </p>
                        </div>
                      </li>
                      <li>
                        <Typography variant="h6">Quantity:</Typography>
                        <div className="value-block">
                          <p>{item.quantity ? `${item.quantity}` : "-"}</p>
                        </div>
                        <Typography
                          className={`${
                            sameAWB && parseInt(sameAWB?.quantityLoaded) !== parseInt(item?.quantity) ? "h1-red" : "h1-nrml"
                          }`}
                          variant="h6 new"
                        >
                          Loaded:
                        </Typography>

                        <div
                          className={`${
                            sameAWB && parseInt(sameAWB?.quantityLoaded) !== parseInt(item?.quantity)
                              ? "value-block-red"
                              : "value-block1"
                          }`}
                        >
                          <p>
                            {item.quantityLoaded
                              ? `${item.quantityLoaded}`
                              : "0"}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            })
          )}
        </div>
      ) : (
        <div className={classes.customCardWrapper}>
          {state.job.airWayBillData?.count === 0 ? (
            <div className="custom-card">
              <div className="card-header">
                <span className="heading-title">No Data found</span>
              </div>
            </div>
          ) : (
            state?.job?.airWayBillData?.rows?.map((data, index) => {
              return (
                <div className="custom-card" key={index}>
                  <div className="card-header">
                    <span className="heading-title">
                      {data.number}
                      {data.sourceId === 4 ? (
                        <span  className={classes.highlightCargoWise}>CargoWise</span>
                      ) : null}
                      {isMismatch(data) ? (
                        <Tooltip title="The total quantity listed on the AWB has been fully delivered" arrow placement="right">
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </span>
                    <div className={classes.moreLinkWrapper}>
                      <div className="bill-items-wrapper">
                        <Loose
                          getIndex={data.id}
                          jobsData={props.jobsData}
                          getAirWayBills={getAirWayBills}
                          getJobs={() => props.getJobs()}
                          isMismatch={isMismatch(data)}
                        />
                        <div className={classes.moreLinkWrapper}>
                          <div className="more-link-block">
                            {data.sourceId === sourceId.CARGO_WISE && getPermissions() &&
                              isRolePermission(getPermissions(), "cargoWise") &&
                              (actionFromPermission(getPermissions(), "cargoWise") === actionPermission.READ_ONLY) && (
                            <span
                              className="more-link transparent"
                              aria-controls="menu"
                              aria-haspopup="true"
                              onClick={(e) => handleClick(e, index)}
                            >
                              <MoreVertIcon/>
                            </span>)}

                            {data.sourceId === sourceId.CARGO_WISE && getPermissions() &&
                              isRolePermission(getPermissions(), "cargoWise") &&
                              (actionFromPermission(getPermissions(), "cargoWise") !== actionPermission.READ_ONLY) && (
                              <span
                                className="more-link"
                                aria-controls="menu"
                                aria-haspopup="true"
                                onClick={(e) => handleClick(e, index)}
                              >
                                <MoreVertIcon />
                              </span>)}

                            {data.sourceId !== sourceId.CARGO_WISE && (
                              <span
                                className="more-link"
                                aria-controls="menu"
                                aria-haspopup="true"
                                onClick={(e) => handleClick(e, index)}
                              >
                              <MoreVertIcon />
                            </span>)}

                            <Menu
                              id={data.index}
                              anchorEl={anchorEl}
                              keepMounted
                              open={getIndex === index && Boolean(anchorEl)}
                              onClose={handleCloseSidebar}
                              className={classes.customMenu}
                              menuprops={{
                                classes: {paper: classes.customMenu},
                              }}
                            >
                              {data.sourceId === sourceId.CARGO_WISE ? getPermissions() &&
                                isRolePermission(getPermissions(), "cargoWise") &&
                                ((actionFromPermission(getPermissions(), "cargoWise") === actionPermission.EDIT_CARGOWISE) ||
                                  actionFromPermission(getPermissions(), "cargoWise") === actionPermission.ALL) && (
                                  <MenuItem onClick={() => handleEditOpen(data)}>
                                    {props.jobsData?.invoiceGenerated === false
                                      ? "Edit"
                                      : "View"}
                                  </MenuItem>):
                                (<MenuItem onClick={() => handleEditOpen(data)}>
                                  {props.jobsData?.invoiceGenerated === false
                                    ? "Edit"
                                    : "View"}
                                </MenuItem>
                              )}

                              {data.sourceId === sourceId.CARGO_WISE ? getPermissions() &&
                                isRolePermission(getPermissions(), "cargoWise") &&
                                (actionFromPermission(getPermissions(), "cargoWise") === actionPermission.ALL) &&
                                props.jobsData?.invoiceGenerated === false && (
                                  <MenuItem
                                    onClick={() => handleDeleteOpenPopup(data)}
                                  >
                                    Delete
                                  </MenuItem>
                                ):
                                props.jobsData?.invoiceGenerated === false && (
                                  <MenuItem
                                    onClick={() => handleDeleteOpenPopup(data)}
                                  >
                                    Delete
                                  </MenuItem>
                                )}
                            </Menu>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <ul>
                      {/* Added remainder tag https://wymap.atlassian.net/browse/MAPTRAK-854 */}
                      <li>
                        <Typography variant="h6">Remainder Reason:</Typography>
                        <div className="value-block">
                          <p>{data?.remainderReason || "-"}</p>
                        </div>
                      </li>
                      <li>
                        <Typography variant="h6">Weight:</Typography>
                        <div className="value-block">
                          <p>{data.weight ? `${data.weight} Kg` : "-"}</p>
                        </div>
                      </li>
                      <li>
                        <Typography variant="h6">Quantity:</Typography>
                        <div className="value-block">
                          <p>{data.quantity ? `${data.quantity}` : "-"}</p>
                        </div>
                        <Typography
                          className={`${
                            data.remainderQty > 0 ? "h1-red" : "h1-nrml"
                          }`}
                          variant="h6 new"
                        >
                          Loaded:
                        </Typography>
                        <div
                          className={`${
                            data.remainderQty > 0
                              ? "value-block-red"
                              : "value-block1"
                          }`}
                        >
                          <p>
                            {data.quantityLoaded ? `${data.quantityLoaded}` : "0"}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            })
          )}
        </div>
      )}
      <DeletePopup
        open={openDeletePopup}
        handleClose={closeDeletePopup}
        handleDelete={() => deleteAWB(initialValue.id)}
        deleteUser={initialValue.number}
        loading={state.job?.deletetingAWB}
        error={error}
      />
      <MergeAwbPopup
        open={openMergeAwbPopup}
        handleClose={handleCloseMergeAwbPopup}
        handleAddEdit={handleAddEdit}
        handleMerge={handleMerge}
        jobId={targetJobId}
        isEditAwb={isEditAwb}
      />
    </div>
  );
}

export default React.memo(AirWayBill);
