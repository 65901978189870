import React from "react";
import { Link } from "react-router-dom";
import {
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";

import { useStore } from "@store/store";
import Pagination from "@components/pagination";
import Loader from "@components/loader";
import checkedIconOrange from "@assets/images/cheked-icon-orange.svg";
import uncheckedIcon from "@assets/images/uncheck-icon.svg";
import indeterminateIcon from "@assets/images/indeterminate-check-box-icon-orange.svg";
import {
  dashboardRequestStatusColors,
  utcToTimezone,
} from "@utils/commonFunctions";
import { jobPoolListingHeader, routes } from "@utils/constant";
import { TableStyle } from "./style";

function TableListing(props) {
  const classes = TableStyle();
  const [state] = useStore();

  const isSelected = (id) => props.selectedArray.indexOf(id) !== -1;
  const numberSelected = props.selectedArray.length;
  const totalRows = state.jobPool?.jobPoolData?.rows.length;

  return (
    <>
      <div className={classes.TableWrapper}>
        <Loader loading={state.jobPool?.loadingJobPool} />
        <TableContainer
          component={Paper}
          className={classes.customJobPoolTable}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="check">
                  <FormControlLabel
                    className="custom-checkbox"
                    control={
                      <Checkbox
                        icon={<img src={uncheckedIcon} alt="CheckBox" />}
                        checkedIcon={
                          <img src={checkedIconOrange} alt="CheckBox" />
                        }
                        indeterminateIcon={
                          <img src={indeterminateIcon} alt="CheckBox" />
                        }
                        indeterminate={
                          numberSelected > 0 && numberSelected < totalRows
                        }
                        onChange={(e) => props.handleMainChange(e)}
                        checked={totalRows > 0 && numberSelected === totalRows}
                        name="check"
                      />
                    }
                  />
                </TableCell>

                {jobPoolListingHeader.map((item, index) => {
                  return (
                    <TableCell key={index} className={item.className}>
                      <div className={`flex-box`}>
                        <span className="title-cell">{item.title}</span>
                        {item.sort && (
                          <TableSortLabel
                            direction={
                              props.orderBy === item.sortTitle
                                ? props.order
                                : "desc"
                            }
                            active={true}
                            onClick={(e) => props.handleSorting(e, item)}
                          ></TableSortLabel>
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {state.jobPool?.jobPoolData?.count === 0 ? (
                <TableRow className="no-data">
                  <TableCell colSpan={9}>
                    <span>No Data Found</span>
                  </TableCell>
                </TableRow>
              ) : (
                state.jobPool?.jobPoolData?.rows?.map((row, index) => {
                  const isItemSelected = isSelected(row.id);

                  return (
                    <TableRow key={index}>
                      <TableCell className="check">
                        <FormControlLabel
                          className="custom-checkbox"
                          control={
                            <Checkbox
                              icon={<img src={uncheckedIcon} alt="CheckBox" />}
                              checkedIcon={
                                <img src={checkedIconOrange} alt="CheckBox" />
                              }
                              onChange={() => props.handleChange(row)}
                              checked={isItemSelected}
                              name="check"
                            />
                          }
                        />
                      </TableCell>
                      <TableCell className="jobId">
                        <Link
                          to={{ pathname: `${routes.jobDetail}/${row.id}` }}
                          target="_blank"
                        >
                          {row.id ? row.id : "-"}
                        </Link>
                      </TableCell>
                      <TableCell>{row?.cities?.name || "-"}</TableCell>
                      <TableCell className="customerName">
                        {row.customers ? row.customers.name : "-"}
                      </TableCell>
                      <TableCell className="cto">
                        {row.ctos ? row.ctos.name : "-"}
                      </TableCell>
                      <TableCell className="jobType">
                        {row.jobTypes ? row.jobTypes.name : "-"}
                      </TableCell>
                      <TableCell className="cargoType">
                        {row.cargoTypes ? row.cargoTypes.name : "-"}
                      </TableCell>
                      <TableCell
                        className={`status ${dashboardRequestStatusColors(
                          row.jobStatuses ? row.jobStatuses.name : "",
                        )}`}
                      >
                        <span>
                          {row.jobStatuses ? row.jobStatuses.name : "-"}
                        </span>
                      </TableCell>
                      <TableCell className="date">
                        {row.createdAt
                          ? utcToTimezone(
                              row.createdAt,
                              row.cities?.timezone,
                              "DD/MM/YYYY",
                            )
                          : "-"}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {state.jobPool?.jobPoolData?.count !== 0 && (
          <div className="pagination-wrapper">
            <Pagination
              count={state.jobPool?.jobPoolData?.count}
              page={props.page}
              handleChangePage={props.handleChangePage}
              rowsPerPage={props.rowsPerPage}
              handleChangeRowsPerPage={props.handleChangeRowsPerPage}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default TableListing;
