import React, { Fragment, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  CircularProgress,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { actionEnum, permissionInfo } from "@utils/constant";

import { useStore } from "@store/store";
import closeIcon from "@assets/images/close.svg";
import uncheckedIcon from "@assets/images/uncheck-icon.svg";
import checkedIcon from "@assets/images/checked-icon.svg";
import { materialCommonStyles } from "@utils/materialCommonStyles";
import { PermissionPopup } from "./style";

function PopupPermission(props) {
  const classes = PermissionPopup();
  const [scroll] = useState("body");
  const [state] = useStore();
  const materilClasses = materialCommonStyles();

  /**
   * We use decimal to decide action of a permission
   * 000 (0 in decimal) is "read only" permission
   * 001 (1 in decimal) is "create" permission
   * 010 (2 in decimal) is "edit" permission
   * 100 (4 in decimal) is "delete" permission
   * 111 (7 in decimal) is "create" + "edit" + "delete" permission
   * 011 (3 in decimal) is "create" + "edit" permission
   * 101 (5 in decimal) is "create" + "delete" permission
   * 110 (6 in decimal) is "edit" + "delete" permission
   */
  const jobActionObj = [
    { key: 0, value: 'Read Only' },
    { key: 3, value: 'Edit' }, // includes Create and Edit
    { key: 7, value: 'Create, Edit and Delete' }
  ];
  const cargoWiseActionObj = [
    { key: 0, value: 'Read Only', isVisible: true },
    { key: 2, value: 'Edit', isVisible: true },
    { key: 7, value: 'Edit and Delete', isVisible: true }
  ]

  // Move Item "CargoWise Jobs Update" next to item "Jobs" in menuData list.
  let menuData = props?.menuData;
  if (menuData) {
    let indexJob = menuData.findIndex(item => item.label === "Jobs");
    let indexCargoWiseJob = menuData.findIndex(item => item.label === "CargoWise")
    let cargoWiseJobObj = menuData[indexCargoWiseJob];
    menuData.splice(indexCargoWiseJob, 1);
    menuData.splice(indexJob + 1, 0, cargoWiseJobObj);
  }

  // Map for Job and CargoWise
  const jobMap = new Map();
  jobMap.set(0, 'Read Only');
  jobMap.set(3, 'Edit');
  jobMap.set(7, 'Create, Edit and Delete');

  const cargoWiseMap = new Map();
  cargoWiseMap.set(0, 'Read Only');
  cargoWiseMap.set(2, 'Edit');
  cargoWiseMap.set(7, 'Edit and Delete');

  // Load Permission list once from api
  if (!props.isUpdatePermission) {
    props.fullPermission.map(item => {
      if (permissionInfo.JOB.name === item.permissionName) {
        props.setJobActionValue(jobMap.get(item.permissionAction));
        props.setIsSelectedJob(true);
      } else if (permissionInfo.CARGO_WISE.name === item.permissionName) {
        props.setCargoWiseActionValue(cargoWiseMap.get(item.permissionAction));
      }
      props.setIsUpdatePermission(true)
      return item;
    });
  }

  // Show/Hide item of Cargo Wise Action when loading permission list
  cargoWiseActionObj.map(item => {
    if (actionEnum.READ_ONLY === props.jobActionValue) {
      if (item.key === 2 || item.key === 7) {
        item.isVisible = false;
      }
    } else {
      item.isVisible = true;
    }
    return item;
  })

  const jobActionChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    props.setJobActionValue(event.target.value);

    const action = event.target.value;

    if (actionEnum.READ_ONLY === action) {
      // 7
      props.setJobActionPermission(
        {
          name: permissionInfo.JOB.name,
          action: 0
        }
      );
    } else if (actionEnum.EDIT === action) {
      // 2
      props.setJobActionPermission(
        { name: permissionInfo.JOB.name,
          action: 3
        }
      );
    } else if (actionEnum.ALL_JOB === action) {
      // 7
      props.setJobActionPermission(
        { name: permissionInfo.JOB.name,
          action: 7
        }
      );
    } else {
      // Nothing to do
    }

    cargoWiseActionObj.map(item => {
      if (actionEnum.READ_ONLY === action) {
        if (item.key === 2 || item.key === 7) {
          item.isVisible = false;
        }
      } else {
        item.isVisible = true;
      }
      return item;
    })

    props.setCargoWiseActionValue("Read Only");
    props.setCargoWiseActionPermission(
        { name: permissionInfo.CARGO_WISE.name,
          action: 0
        }
    );
  };

  const cargoWiseActionChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    props.setCargoWiseActionValue(event.target.value);

    const action = event.target.value;

    if (props.permissionData.includes(permissionInfo.CARGO_WISE.id)) {
      // Remove Cargo Wise item before select other option
      const index = props.permissionData.indexOf(permissionInfo.CARGO_WISE.id);
      props.permissionData.splice(index, 1);
    }

    if (actionEnum.READ_ONLY === action) {
      // 0
      props.permissionData.push(permissionInfo.CARGO_WISE.id);
      props.setCargoWiseActionPermission(
        { name: permissionInfo.CARGO_WISE.name,
          action: 0
        }
      );
    } else if (actionEnum.EDIT === action) {
      // 2
      props.permissionData.push(permissionInfo.CARGO_WISE.id);
      props.setCargoWiseActionPermission(
        { name: permissionInfo.CARGO_WISE.name,
          action: 2
        }
      );
    } else if (actionEnum.ALL_CARGOWISE === action) {
      // 6
      props.permissionData.push(permissionInfo.CARGO_WISE.id);
      props.setCargoWiseActionPermission(
        { name: permissionInfo.CARGO_WISE.name,
          action: 7
        }
      );
    } else {
      // Nothing to do
    }
  }
  const openAction = (event) => {
    event.preventDefault();
    event.stopPropagation();
  }

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.customModal}
      scroll={scroll}
    >
      <div className="close-modal">
        <img src={closeIcon} alt="Close" onClick={props.handleClose} />
      </div>
      <form noValidate autoComplete="off" className={classes.customForm}>
        <DialogTitle id="alert-dialog-title">Permission</DialogTitle>
        <DialogContent>
          <div className="form-row">
            <div className="form-gourp full-width">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="permission">Select Permission</InputLabel>
                <Select
                  labelId="permission"
                  value={props.permissionData}
                  onChange={props.handleChange}
                  displayEmpty
                  label="Permission"
                  className={materilClasses.customSelect}
                  multiple
                  MenuProps={{
                    classes: { paper: materilClasses.customSelect },
                  }}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <>Select Permission</>;
                    }

                    return props?.menuData
                      ?.filter((m) => selected.includes(m.value))
                      .map((m) => m.label)
                      .join(",");
                  }}
                  IconComponent={() => <ExpandMore />}
                >
                  <MenuItem value="" disabled>
                    Select Division
                  </MenuItem>
                  {props.menuData?.map((item, index) => {
                    if (item.label === "CargoWise") {
                      if (props.isOpenCargoWiseJob) {
                        return (
                          <MenuItem
                            key={index}
                            value={item.value}
                            className={classes.disableClick + " disableHover"}
                          >
                            <ListItemText className={classes.cargoWiseTitle} primary={item.label}/>
                            <ListItemText className={classes.listItemAction + " " + classes.enableClick} >
                              <Select
                                labelId="cargoWiseAction"
                                value={props.cargoWiseActionValue}
                                onChange={cargoWiseActionChange}
                                onClick={openAction}
                                displayEmpty
                                className={classes.actionDropdown}
                                MenuProps={{
                                  classes: {paper: materilClasses.customSelect},
                                }}
                                IconComponent={() => <ExpandMore />}
                              >
                                {cargoWiseActionObj.map(item=> {
                                  if (item.isVisible) {
                                    return (<MenuItem
                                        key={item.key}
                                        value={item.value}
                                        className={classes.actionMenuItem}
                                        MenuProps={{
                                          classes: { paper: materilClasses.customSelect },
                                        }}
                                    >
                                      {item.value}
                                    </MenuItem>)
                                  }})
                                }
                              </Select>
                            </ListItemText>
                          </MenuItem>
                        );
                      }
                    } else if (item.label === "Jobs") {
                      return (
                        <MenuItem
                          key={index}
                          value={item.value}
                          className="custom-checkbox custom-Multicheckbox"
                        >
                          <Checkbox
                            checked={
                              props.permissionData.indexOf(item.value) > -1
                            }
                            icon={<img src={uncheckedIcon} alt="CheckBox" />}
                            checkedIcon={<img src={checkedIcon} alt="CheckBox" />}
                          />
                          <ListItemText primary={item.label} />
                          { props.isEnableJobAction?
                            <ListItemText className={classes.listItemAction + " " + classes.enableClick} >
                              <Select
                                  labelId="jobAction"
                                  value={props.jobActionValue}
                                  onChange={jobActionChange}
                                  onClick={openAction}
                                  displayEmpty
                                  className={classes.actionDropdown}
                                  MenuProps={{
                                    classes: {paper: materilClasses.customSelect},
                                  }}
                                  IconComponent={() => <ExpandMore />}
                              >
                                {jobActionObj.map(item=> {
                                  return (<MenuItem
                                      key={item.key}
                                      value={item.value}
                                      className={classes.actionMenuItem}
                                      MenuProps={{
                                        classes: { paper: materilClasses.customSelect },
                                      }}
                                  >
                                    {item.value}
                                  </MenuItem>)
                                })
                                }
                              </Select>
                            </ListItemText> : ""}
                        </MenuItem>
                      )
                    } else {
                      return (
                        <MenuItem
                          key={index}
                          value={item.value}
                          className="custom-checkbox custom-Multicheckbox"
                        >
                          <Checkbox
                            checked={
                              props.permissionData.indexOf(item.value) > -1
                            }
                            icon={<img src={uncheckedIcon} alt="CheckBox" />}
                            checkedIcon={<img src={checkedIcon} alt="CheckBox" />}
                          />
                          <ListItemText primary={item.label} />
                        </MenuItem>
                      );
                    }
                    return true;
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="bottom-button-block">
          <Button
            className="primary-btn gray-border-btn"
            color="inherit"
            disableElevation
            underlinenone="true"
            onClick={props.handleClose}
            type="reset"
          >
            CANCEL
          </Button>
          <Button
            className="orange-btn primary-btn"
            color="inherit"
            disableElevation
            underlinenone="true"
            type="button"
            onClick={props.assignPermission}
            disabled={state.rolesPermission.settingRolesPermission}
          >
            {state.rolesPermission.settingRolesPermission ? (
              <CircularProgress color="inherit" />
            ) : (
              "Assign"
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default PopupPermission;
