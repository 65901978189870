import {
  ASSIGN_DRIVER_JOB_POOL,
  ASSIGN_DRIVER_JOB_POOL_FAILURE,
  ASSIGN_DRIVER_JOB_POOL_PROGRESS_FAILURE,
  ASSIGN_DRIVER_JOB_POOL_PROGRESS_SUCCESS,
  ASSIGN_DRIVER_JOB_POOL_SUCCESS,
  FETCH_JOB_POOL,
  FETCH_JOB_POOL_FAILURE,
  FETCH_JOB_POOL_SUCCESS,
} from "@utils/actionTypes";

const INIT_STATE = {
  loadingJobPool: false,
  jobPoolData: null,
  assigningDriver: false,
  assigningDriverProgressSuccess: 0,
  assigningDriverProgressFailure: 0,
  assignedDriverData: null,
};

const JobsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_JOB_POOL:
      return { ...state, loadingJobPool: true };
    case FETCH_JOB_POOL_SUCCESS:
      return {
        ...state,
        jobPoolData: action.payload,
        loadingJobPool: false,
      };
    case FETCH_JOB_POOL_FAILURE:
      return {
        ...state,
        jobPoolData: action.payload,
        loadingJobPool: false,
      };

    case ASSIGN_DRIVER_JOB_POOL:
      return { ...state, assigningDriver: true };
    case ASSIGN_DRIVER_JOB_POOL_SUCCESS:
      return {
        ...state,
        assignedDriverData: action.payload,
        assigningDriver: false,
        assigningDriverProgressSuccess: 0,
        assigningDriverProgressFailure: 0,
      };
    case ASSIGN_DRIVER_JOB_POOL_FAILURE:
      return {
        ...state,
        assignedDriverData: action.payload,
        assigningDriver: false,
        assigningDriverProgressSuccess: 0,
        assigningDriverProgressFailure: 0,
      };
    case ASSIGN_DRIVER_JOB_POOL_PROGRESS_SUCCESS:
      return {
        ...state,
        assigningDriverProgressSuccess:
          state.assigningDriverProgressSuccess + 1,
      };
    case ASSIGN_DRIVER_JOB_POOL_PROGRESS_FAILURE:
      return {
        ...state,
        assigningDriverProgressFailure:
          state.assigningDriverProgressFailure + 1,
      };

    default:
      return state;
  }
};
export default JobsReducer;
