import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import { ConfirmPopupStyle } from "./style";
import { Link } from "react-router-dom";
import { routes } from "@utils/constant";
import closeIcon from "@assets/images/close.svg";

function MergeAwbPopup(props) {
  const classes = ConfirmPopupStyle();
  const [scroll] = useState("body");
  return (
    <div className={classes.ConfirmWrapper}>
      <Dialog
        open={props.open}
        scroll={scroll}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.customModal}
      >
        <div className="close-modal">
          <img src={closeIcon} alt="Close" onClick={props.handleClose} />
        </div>
        <DialogTitle id="alert-dialog-title" className="title">
          Confirm
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              Job{" "}
              <Link
                to={`${routes.jobDetail}/${props.jobId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.jobId}
              </Link>{" "}
              has the same AWB as the one you are{" "}
              {props.isEditAwb ? " updating" : " adding"}. Please choose to
              merge or {props.isEditAwb ? " update " : " add "} AWB to this job.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.handleAddEdit}
            color="primary"
            className="orange-btn primary-btn"
          >
            {props.isEditAwb ? "Update" : "Add"}
          </Button>
          <Button
            onClick={props.handleMerge}
            color="primary"
            className="orange-btn primary-btn"
          >
            Merge
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default MergeAwbPopup;
